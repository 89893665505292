import React, { useEffect } from 'react'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel';
import { user_list_for_friend, friend_reason_list, add_friend, delete_friend } from '../../../services/user_apis';
import $ from 'jquery';
import { CircularProgress } from '@mui/material';
import env from 'react-dotenv'
import { useLocation } from 'react-router-dom';

const Add_Classmate = ({ socketData }) => {
    
    const location = useLocation();

    const [circular, setcircular] = React.useState(true)
    const [friend_list, setfriend_list] = React.useState([])
    const [friendreasonList, setFriendReasonList] = React.useState([])
    const [search_text, setSearch_text] = React.useState([])
    const [sendRequestData, setSendRequestData] = React.useState()

    // console.log("Data Add Classmate",location)

    useEffect(() => {
           friend_list_api("")
           friend_list_reason_api()    
        
    }, [])

    useEffect(() => {
        if(location.state!=null){
            setfriend_list([])
            // console.log("Data Add Classmate",location)
           friend_list_api(location.state.data)
           friend_list_reason_api()    
        }
    }, [location.state])

    const friend_list_api = async (searchText) => {
        setcircular(true)
        setSendRequestData([])
        const user = JSON.parse(localStorage.getItem('user'))
        const post_body = {
            searchtext: searchText,
            class_id: user.class_id,
            school_id: user.school_id,
            division_id:user.division_id,
            user_role: user.user_role
        }
        setcircular(false)
        const response = await user_list_for_friend(post_body)
        if (response.code === 1) {
            setfriend_list(response.data)
            // console.log("all friend list   ====> ", response.data)
        }else if(response.code === 2) {
            setfriend_list([])
        }

    }

    const friend_list_reason_api = async () => {
        setcircular(true)
        const response = await friend_reason_list()
        setcircular(false)
        if (response.code === 1) {
            setFriendReasonList(response.data)
            // console.log("friend reason list   ====> ", response.data)
        }

    }

    const removefriend = async (user_id) => {
        setcircular(true)
        const post_body = {
            delete_friend_id: user_id,
        }

        const response = await delete_friend(post_body)
        // console.log("delete friend response ====> ", response)
        setcircular(false)
        if (response.code === 1) {
            friend_list_api(search_text)
        }
    }

    return (
        <React.Fragment>

            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 pr-md-0">
                                <div className="main-content">
                                    <div className="friend-list add-friend-section">
                                        <div className="search">
                                            <div>
                                                <h4>
                                                    Browse other classmates
                                                </h4>
                                                <p>
                                                    Connect with other classmates below
                                                </p>
                                            </div>
                                            <div>
                                                <input type="text" placeholder="Search Friends by name" onChange={(e) => {
                                                    setSearch_text(e.target.value)
                                                    friend_list_api(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        {circular && <CircularProgress className="text-center" />}
                                        <div className="row">
                                            {friend_list.map((item, i) => {
                                                return (
                                                    <div className="col-lg-3 col-md-6 col-6" key={i}>
                                                        <div className="box">
                                                            <div className="p-pic">
                                                                <img src={item.avatar_full_path != null ? item.avatar_full_path : "assets/web/profile-1.png"} />
                                                            </div>
                                                            <div className="box-content">
                                                                <h2>
                                                                    {item?.display_user_name != "" ? item?.display_user_name : item.username}
                                                                </h2>
                                                                <p>
                                                                    It is so hot today
                                                                </p>
                                                                <div className={`action`}>
                                                                {/* {item.friend_status !== "add_friend" ? 
                                                                    <select className={`select  select${item.user_id}`}>
                                                                        {friendreasonList.map((item) =>
                                                                            <option value={item.friend_reason_id}>
                                                                                {item.friend_reason_message}
                                                                            </option>
                                                                        )}
                                                                    </select>
                                                                    : null} */}

                                                                    {item.friend_status === "add_friend" ?
                                                                        <button onClick={() => setSendRequestData(item)} className="add-friend" data-target="#friend-RequestModal" data-toggle="modal">
                                                                            <img src={`${env.ADD_USER_PNG}add-user1.png`} /> Add Friend
                                                                        </button>
                                                                        :
                                                                         <button onClick={() => removefriend(item.user_id)} className="cancel-friend">
                                                                        <img src={`${env.ADD_USER_PNG}add-user1.png`} /> Cancel Friend Request
                                                                    </button>
                                                                        // <button onClick={() => removefriend(item.user_id)} className="blocks-friend"></button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Modal isAcceptRequest={false} friend_reason_list={friendreasonList} send_friend_request_data={sendRequestData} isUpdate={(data) => data === true ? friend_list_api(search_text): setSendRequestData([])} />
        </React.Fragment>
    )
}

export default Add_Classmate
