import GLOBAL_CONSTANT from '../config/global';

const axios = require('axios');
const url = `${GLOBAL_CONSTANT.API_URL}/api/v1`;
const adminurl = `${GLOBAL_CONSTANT.API_URL}/api/admin`;
const { axiosClient } = require('./apiClient')

// 'Access-Control-Allow-Origin': '*',


const headers = {
    'api-key': 'n4lBiwpD7XqiqSYWQkczLCiGl5QBpPii ',
    'Content-Type': 'text/plain',
}
if (GLOBAL_CONSTANT.OFFLINE === 'true') headers['offline-access'] = 'true';

export const user_login1 = async (user) => {
    try {
        const response = await axios.post(`${url}/user/user_login`, user, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const online_offline = async () => {
    try {
        let response = await fetch(url, {headers: { "offline-access": `${GLOBAL_CONSTANT.OFFLINE}` }});
        return await response.json();
    } catch (e) {
        return await ({ 
            "code": -2,
            "message": "Hi Friends, we are making some changes to the platform to serve you better. It will be up again shortly. Can't wait to see you back again! :)"
        });
    }
}

export const user_login = async (user) => {
    const response = await axiosClient.post(`user/user_login`, user);
    return response.data
}

export const user_annoucement_api = async (user) => {
    const response = await axiosClient.post(`announcement/announcement_list`, { "page_token": 1 }, { headers: headers });
    return response.data
}

export const contact_us_api = async (body) => {
    let user = JSON.parse(localStorage.getItem('user'))
    headers.token = user.token
    const response = await axiosClient.post(`common/add_contact_us`, body, { headers: headers });
    return response.data
}

export const user_logout = async (user) => {
    try {
        // user = JSON.parse(user)
        headers.token = user.token
        const response = await axiosClient.post(`user/user_logout`, { "page_token": 1 }, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const contact_us = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`common/add_contact_us`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const fun_zone_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`fun_zone/fun_zone_list`, { "fun_zone_category": body, "class_id": user.class_id, "classes": user.classes }, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const add_post = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token;
        body.permission = user.user_role;
        const response = await axiosClient.post(`post/add_post`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const edit_profile = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`post/add_post`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const post_list_api = async () => {
    try {

        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`post/home_post_list`, { "page_token": 1, limit: 10, school_id: user.school_id }, { headers: headers });
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const post_list_api_school = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`post/home_post_list`, body, { headers: headers });
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const postListCategoryWise = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`post/post_list`, body, { headers: headers });
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const post_list_api_for_class = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const body1 = {
            school_id: user.school_id,
            class_id: body.class_id,
            division_id: body.division_id,
            limit : 10
        }
        const response = await axiosClient.post(`post/home_post_list`, body1, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const teacher_class_division_list = async () => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`division/teacher_class_division_list`, { "page_token": 1 }, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const post_specific_user_api = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`post/post_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const class_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`class/class_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const user_list_for_friend = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user/user_list_for_friend`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const my_friend_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`friend/friend_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const school_detail = async (school_id) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const body = {
            school_id
        }
        const response = await axiosClient.post(`school/school_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const limit_home_work_list = async () => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        var body = {
            "class_id": user.class_id,
            "student_id": user.user_id,
            "division_id": user.division_id,
            "school_id": user.school_id,
            "last_submission_date": true,
        }
        const response = await axiosClient.post(`homework/homework_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const home_work_list = async (school_id) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const body = {
            school_id
        }
        const response = await axiosClient.post(`homework/homework_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const user_list_api = async (class_id,division_id) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const body = {
            "class_id": class_id,
            "division_id": division_id,
            "user_role": user.user_role
        }
        const response = await axiosClient.post(`user/user_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const user_list_student_api = async (class_id,division_id) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const body = {
            "class_id": class_id,
            "division_id": division_id,
            "user_role": "student"
        }
        // console.log("APi call user list 2nd one",body);
        // console.log(body);
        const response = await axiosClient.post(`user/user_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const lecture_list_api = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`lecture/lecture_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const add_comment_api = async (comment) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const body = {
            school_id: user.school_id,
            post_id: comment.post_id,
            to_user_id: comment.to_user_id,
            comment: comment.comment
        }
        // console.log("add comment api")
        const response = await axiosClient.post(`comment/add_comment`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}
export const comment_list_api = async (post) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const body = {
            post_id: post.post_id,
        }
        const response = await axiosClient.post(`comment/comment_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const like_dislike = async (post) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`like_dislike/like_dislike`, post, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const postDetails = async (post) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`post/post_detail`, post, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const commentList = async (post) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`comment/comment_list`, post, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const division_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`division/division_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}


export const friend_reason_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`friend/friend_reason_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}
export const add_friend = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`friend/add_friend`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}
export const delete_friend = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`friend/delete_friend`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const notification_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`common/notification_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const notification_change_status = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`friend/accept_reject_request`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const get_chat_room_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`chat/get_chat_room_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const get_chat_room_history = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`chat/get_chat_history`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const get_user_details = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user/user_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const update_user_detail = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user/update_user_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const user_interest_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        body.user_id = user.user_id;
        const response = await axiosClient.post(`user_interest/user_interest_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const update_user_interest_details = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user_interest/update_user_interest_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}
export const get_homework_details = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`homework/homework_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const home_work_list_body = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`homework/homework_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

// add lecture
export const add_lecture = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`lecture/add_lecture`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

// delete lecture
export const delete_lecture = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`lecture/delete_lecture`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

// update lecture
export const update_lecture = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`lecture/update_lecture_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

// report message
export const add_report = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`report/add_report`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const add_home_work = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`homework/add_homework`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const add_homework_submission = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`submission/add_submission`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const delete_home_work = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`homework/delete_homework`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}


export const update_home_work = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`homework/update_homework_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const submission_list_teacher = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`submission/submission_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const result_list_teacher = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`submission/submission_result`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const submission_details = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`submission/submission_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const submission_details_update = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`submission/update_submission_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const get_content_page_details = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axios.post(`${adminurl}/common/get_content_page_details`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const cheer_me_up = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user/cheer_me_up`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const get_faqs_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axios.post(`${adminurl}/faqs/get_faqs_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const get_chat_bot_questionList = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`bot/get_questions`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const avatar_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user/avatar_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const report_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`report/report_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const update_report = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`report/update_report_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const announcement_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`announcement/announcement_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}
export const add_announcement = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`announcement/announcement_add`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const update_announcement = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`announcement/announcement_update`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const delete_announcement = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`announcement/announcement_delete`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const create_chat_room = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`chat/create_chat_room`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const share_post = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`post/share_post`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const teacher_list = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user/teacher_list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const division_details_api = async (body) => {
    // console.log(body);
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`division/division_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const update_division_detail = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`division/update_division_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const update_school_detail = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`school/update_school_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}


export const mark_as_read = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`common/mark_as_read_notification`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const click_delete_api = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`post/delete_post`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}
export const delete_comment = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`comment/delete_comment`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const survey_details = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user/survey_detail`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const submit_survey = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`user/survey_user_response`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while callig error")
    }
}

export const save_user_bot_data = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`bot/save_user_bot_data`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while calling error")
    }
}

export const get_bot_questions = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`bot/get_bot_questions`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while calling error")
    }
}

export const get_claire_bot_user_status = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`bot/get_claire_bot_user_status`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while calling error")
    }
}

export const set_claire_bot_user_status = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`bot/set_claire_bot_user_status`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while calling error")
    }
}

export const get_general_notifications = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`common/get_general_notifications`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while calling error")
    }
}

export const get_tutorials = async (body) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'))
        headers.token = user.token
        const response = await axiosClient.post(`tutorial/list`, body, { headers: headers })
        return response.data
    }
    catch (e) {
        console.log(e, "while calling error")
    }
}
