const GLOBAL_CONSTANT = {
    // "API_URL": "https://api.beta.cybersmarties.co.nz",
    // "API_URL": "https://api.cybersmarties.co.nz",
    // "API_URL": "http://localhost:4000",
    // "TIME_ZONE": "Pacific/Auckland",
    // "TIME_ZONE": "Pacific/Auckland",
    // "TIME_ZONE": "Asia/Kolkata",
    // "TIME_ZONE": "Europe/Dublin",
    "API_URL": "https://api.beta.cybersmartiesusa.com",
    "TIME_ZONE": "America/New_York",
    "OFFLINE": "true",
    // "OFFLINE": "false",
}

export default GLOBAL_CONSTANT;