import S3 from 'react-aws-s3';

const S3_BUCKET = 'cybersmarties-prod'; //Add s3 bucket
const REGION = 'eu-west-1'; // Add Region
const ACCESS_KEY = 'AKIA4R7PQJRBQ6VL42UW'; // Add access key
const SECRET_ACCESS_KEY = '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'; //Set Aws secreat key
const DIR_NAME = "cybersmarties/homework"; //

let config = {
  bucketName: S3_BUCKET,
  dirName: DIR_NAME,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
}


export const uploadImage = async (file, callbackData) => {
  const ReactS3Client = new S3(config);
  // console.log("upload file data  ====>  ", file, "ReactS3Client  ==== ", ReactS3Client)
  const newFileName = new Date().getTime() + file.name;
  try {
    ReactS3Client
      .uploadFile(file, newFileName)
      .then(data => {
        // console.log("success data ===>", data)
        if (data.status === 204) {
          callbackData(newFileName)
        }
      })
      .catch(err => {
        // console.log("Error on s3")
        console.error(err)
      })
  }
  catch (e) {
    console.log(e, "while callig error")
  }
}


export const uploadCoverImage = async (file, folderName, callbackData) => {
  // console.log("cover image")
  let configDivision = {
    bucketName: S3_BUCKET,
    dirName: "cybersmarties/division/" + folderName,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  }
  const ReactS3Client = new S3(configDivision);
  // console.log("upload file data  ====>  ", file, "ReactS3Client  ==== ", ReactS3Client)
  const newFileName = new Date().getTime() + file.name;
  try {
    ReactS3Client
      .uploadFile(file, newFileName)
      .then(data => {
        // console.log("success data ===>", data)
        if (data.status === 204) {
          callbackData(newFileName)
        }
      })
      .catch(err => {
        // console.log("Error on s3")
        console.error(err)
      })
  }
  catch (e) {
    console.log(e, "while callig error")
  }
}

export const uploadCoverSchoolImage = async (file, folderName, callbackData) => {
  // console.log("cover image1")
  let configDivision = {
    bucketName: S3_BUCKET,
    // dirName: "cybersmarties/school/" + folderName,
    dirName: "cybersmarties/school",
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  }
  const ReactS3Client = new S3(configDivision);
  // console.log("upload file data  ====>  ", file, "ReactS3Client  ==== ", ReactS3Client)
  const newFileName = new Date().getTime() + file.name;
  try {
    ReactS3Client
      .uploadFile(file, newFileName)
      .then(data => {
        // console.log("success data ===>", data)
        if (data.status === 204) {
          callbackData(newFileName)
        }
      })
      .catch(err => {
        console.log("Error on s3")
        console.error(err)
      })
  }
  catch (e) {
    console.log(e, "while callig error")
  }
}

export const uploadCoverImageUser = async (file, folderName, callbackData) => {
  // console.log("cover image2")
  let configDivision = {
    bucketName: S3_BUCKET,
    dirName: "cybersmarties/user/" + folderName,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  }
  const ReactS3Client = new S3(configDivision);
  // console.log("upload file data  ====>  ", file, "ReactS3Client  ==== ", ReactS3Client)
  const newFileName = new Date().getTime() + file.name;
  try {
    ReactS3Client
      .uploadFile(file, newFileName)
      .then(data => {
        // console.log("success data ===>", newFileName)
        if (data.status === 204) {
          callbackData(newFileName)
        }
      })
      .catch(err => {
        // console.log("Error on s3")
        console.error(err)
      })
  }
  catch (e) {
    console.log(e, "while callig error")
  }
}