import React, { useEffect } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import myclassbg from "./my-class-img/myclassbg.png"
import { CircularProgress } from '@mui/material';
import { Button } from '@material-ui/core';
import { teacher_class_division_list } from '../../../services/user_apis'
import { post_list_api_for_class } from '../../../services/user_apis';
import { home_work_list } from '../../../services/user_apis';
import { user_list_api } from '../../../services/user_apis';
import { lecture_list_api } from '../../../services/user_apis';
import { division_details_api } from '../../../services/user_apis';
import { user_annoucement_api, postListCategoryWise, announcement_list, teacher_list, school_detail } from '../../../services/user_apis'
import { Link, useNavigate } from 'react-router-dom';
import PostListing from "../home/PostListing";
import { todayHomework } from "../../common";
import moment from 'moment'

const My_Class = ({ homeworknotice, socketData }) => {

    let user = JSON.parse(localStorage.getItem('user'));
    const [ datalimit, setDatalimit ] = React.useState({ limit: 10, page: 1 });
    const [circular, setcircular] = React.useState(true)
    let [class_list, setClassList] = React.useState([])
    let [post, setpost] = React.useState([])
    let [homeworklist, sethomeworklist] = React.useState([])
    let [annoucement, setannoucement] = React.useState([])
    let [myfriends, setmyfriends] = React.useState([])
    let [lecture, setlecturelist] = React.useState([])
    let navigate = useNavigate();
    let [classDetails, setClassDetails] = React.useState({});
    let [cheermeup, setCheermeup] = React.useState([]);
    const [reportPostData, setReportPostData] = React.useState("")
    let [divisionDetails, setDivisionDetails] = React.useState(null)
    let [instructionData, setInstructionData] = React.useState("");
    let [schoolData, setschooldata] = React.useState([]);
    let [reportType, setReportType] = React.useState("");
    let [classTeacherList, setClassTeacherList] = React.useState([]);

    useEffect(() => {
        const user_list = async () => {
            try {
                const response = await user_list_api(user.class_id,user.division_id)
                if (response.code === 1) {
                    setmyfriends(response.data)
                    // console.log("Memebers in class", response.data)
                    // setcircular(false)
                }
            }
            catch (e) {
                navigate('/')
            }
        }
        user_list()
        announcement_listApi()
        school_details()
        classMember_list()
    }, [])

    const school_details = async () => {

        const school_data = await school_detail(user.school_id)
        if (school_data.code === 1) {
            setschooldata(school_data.data);
        } else {
            setschooldata("")
        }

    }

    const announcement_listApi = async () => {
        let post_body = {
            class_id: user.class_id,
            division_id: user.division_id,
            school_id: user.school_id
        }
        const response = await announcement_list(post_body)
        if (response.code === 1) {
            setannoucement(response.data)
        } else {
            setannoucement("")
        }
    }

    useEffect(() => {
        const class_list = async () => {
            if (user.user_role === "teacher") {
                const response = await teacher_class_division_list()
                if (response.code === 1) {
                    setClassList(response.data)
                    // setcircular(false)
                }
                setcircular(false)
            }
        }
        class_list()
    }, [])

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        setClassDetails({ "class_name": user.class_name, "school_name": user.school_name, "division_name": user.division_name })
        getDivisionDetails(user.division_id)
        post_list_detail()
    }, [datalimit])

    const post_list_detail = async () => {
        var body = {
            ...{ "post_category": "class", "class_id": user.class_id, "school_id": user.school_id, "division_id": user.division_id },
            ...datalimit
        };
        try {
            const response = await postListCategoryWise(body)
            if (response.code === 1) {
                setpost(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }
        setcircular(false)
    }

    const classMember_list = async (division_id) => {
        let post_body = {
            division_id: user.division_id
        }
        const response = await teacher_list(post_body)
        // console.log("teacher list  ===> ", response)
        if (response.code === 1) {
            setClassTeacherList(response.data)
        } else {
            setClassTeacherList([])
        }

    }

    const getDivisionDetails = async (division_id) => {
        // console.log("call division list data ")
        let post_body = {
            division_id: division_id
        }
        try {
            const response = await division_details_api(post_body)
            // console.log("Divisions Details api  ===> ", response)
            if (response.code === 1) {
                setDivisionDetails(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }

    }

    const updateLimit = () => {
        setcircular(true);
        setDatalimit({...datalimit, "limit": Number(datalimit.limit) + 10 })
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (post.length < datalimit.limit) {
                return <div className="no-more-activities">No more activities found.</div>
            }
            return <Button variant="outlined" size="medium" onClick={updateLimit}>See More</Button>
        }
    }

    const getPassDataOtherUser = (data) => {
        var dataPass = { "user_id": data.user_id, "username": data.username, "display_user_name": data.display_user_name, "user_about_me": data.user_about_me, "avatar_full_path": data.avatar_full_path }
        return dataPass
    }

    return (
        <React.Fragment>

            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid text-left">
                        <div className="row">
                            <div className="col-lg-8 pr-md-0">
                                <div className="main-content">
                                    <div className='my-profile school-profile myclass-profile'>
                                        <div className="p-banner" style={{ backgroundimage: `url(${(divisionDetails === null) ? myclassbg : divisionDetails.division_cover_image_full_path})` }}>
                                            <img src={(divisionDetails === null) ? myclassbg : divisionDetails.division_cover_image_full_path} />
                                            {
                                                user.user_role === "teacher" && <div className="my-class-dropdown">
                                                    <select className="form-control">
                                                        {class_list.map((value, i) => (

                                                            <option key={i}>{value.class_name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            }

                                        </div>
                                        <div className="p-icon">
                                            <div className="p-main-pic">
                                                <img src={(divisionDetails === null) ? `${env.FRIST_CLASS_SVG}first-class.svg` : divisionDetails.division_logo_image_full_path} />
                                            </div>
                                        </div>
                                        <div className="p-name pb-3">
                                            {classDetails.class_name + " - " + classDetails.division_name}
                                        </div>
                                        <div className="post-content">
                                            <ul className="nav nav-tabs">
                                                <li><a href="#posts" data-toggle="tab" className="active">Posts</a></li>
                                                <li><a href="#info" data-toggle="tab"> Info</a></li>
                                                <li><a href="#announcements" data-toggle="tab" className="border-0" onClick={announcement_listApi}> Announcements</a></li>
                                                <li><a href="#teacher" data-toggle="tab"> Teachers</a></li>
                                            </ul>
                                            {
                                                user.user_role === "teacher" && <div className="profile-tab-rightside">
                                                    <div className="createnewclass-btn">
                                                        <a href="#" data-target="#ADDClass_Modal" data-toggle="modal">Create New lecture</a>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="posts">
                                            <div className="profile-info">
                                                {
                                                    <PostListing data={post}
                                                        callBackData={(data) => {
                                                            setReportType(data.type)
                                                            setReportPostData(data.reportdata)
                                                        }} />
                                                }
                                                <br />
                                                <center>{pagignation()}</center>
                                            </div>
                                            <br />
                                            <br />
                                        </div>
                                        <div className="tab-pane" id="info">
                                            <div className="about-profile-info">
                                                <ul>
                                                    <li>
                                                        <p>Name of Class: <b style={{ 'color': 'black' }}>{classDetails.class_name + " - " + classDetails.division_name}</b></p>
                                                    </li>
                                                    <li>
                                                        <p>School: <b style={{ 'color': 'black' }}>{classDetails.school_name}</b></p>
                                                    </li>
                                                    <li>
                                                        <p>Members in Class: <b style={{ 'color': 'black' }}>{myfriends.length}</b></p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="announcements">
                                            <div className="row justify-content-start pt-4">
                                                {annoucement && annoucement.map((value, i) => (
                                                    <div className="col-lg-6 mb-3" key={i}>
                                                        <div className="other-class-box">
                                                            <div className="row w-100">
                                                                <div className="col-md-2 annoucement-img">
                                                                    <img src="assets/web/annoucement.png" />
                                                                </div>
                                                                <div className="col col-md-10 picnic">
                                                                    <label>{value.title}</label>
                                                                    <div className="picnic-team">
                                                                        {/* <img src="assets/web/team.png" /> */}
                                                                    </div>
                                                                    <div className="picninc-poster">
                                                                        <img className='w-100' height={'200px'} style={{ "object-fit": "cover" }} src={value.announcement_image_full_path} />
                                                                    </div>
                                                                    <div className="register w-100 announcemetn-description">
                                                                        {value.description}
                                                                    </div>
                                                                    {value.description.length > 180 ? <a className="add-friend " href="javaScript:" data-target="#Instruction_Modal" data-toggle="modal" onClick={() => setInstructionData(value?.description)} >Read More</a> : null}
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                ))
                                                }

                                            </div>
                                        </div>
                                        <div className="tab-pane" id="teacher">
                                            <div className="about-profile-info">
                                                <ul>
                                                    {classTeacherList && classTeacherList.map((item, i) => (
                                                        <li key={i}>
                                                            <p > {item.role === "class_teacher" ? "Class Teacher": "Subject Teacher"} <b style={{ 'color': 'black' }}>{item.display_user_name ? item.display_user_name : item.user_name}</b></p>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div className="tab-pane" id="homework">
                                            <div className="row justify-content-center pt-4">
                                                {
                                                    homeworklist && homeworklist.map((value) => (
                                                        <div className="col-md-6">
                                                            <div className="academic-box">
                                                                <div className="academic-header">
                                                                    <div className="academic-profile">
                                                                        <img src="assets/images/academic.png" />
                                                                    </div>
                                                                    <div className="academic-title">
                                                                        <span className="float-right text-warning">Pending</span>
                                                                        <h3>{value.homework_title}</h3>
                                                                    </div>
                                                                </div>
                                                                <div className="academic-body">
                                                                    <label className="posted">Posted by: <b>{classDetails.class_name + " - " + classDetails.division_name}</b></label>
                                                                    <h3><a href="#">{value.homework_question}</a></h3>
                                                                    <label className="submission-date">Last Submission Date: {value.homework_last_submission_date}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                        <div className="tab-pane" id="classes">
                                            <div className="row mt-3">

                                                {
                                                    lecture && lecture.map((value) => (
                                                        <div className="col-lg-6">
                                                            <div className="other-class-box">
                                                                <div className="className-icon">
                                                                    <img src={`${env.ONLINECLASS_PNG}onlineclass.png`} />
                                                                </div>
                                                                <div className="className">
                                                                    <div className="class-info">
                                                                        <div className="key">Class: </div>
                                                                        <div className="pair"> {classDetails.class_name + " - " + classDetails.division_name}</div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key">Timings:</div>
                                                                        <div className="pair"> {value.lecture_time}</div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key">Teacher name :  {value.lecture_teacher_name}</div>
                                                                        <div className="pair"> {value.lecuture_teacher_name}</div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key">Duration: {value.lecture_duration}</div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key">Link:  <a target={"_blank"} href={value.lecture_link} >{value.lecture_link}</a></div>
                                                                    </div>
                                                                    <div className="class-info">
                                                                        <div className="key">Instruction:  {value.lecture_instruction}</div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div> */}



                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-side">
                                    <div className="school-right">
                                        <h3>School</h3>
                                        <div className="school-img">
                                            {schoolData != "" &&
                                                <>
                                                    <img src={schoolData.school_logo_full_path} className="s-img" />
                                                    <div className="school-name">
                                                        <h4>{schoolData.school_name}</h4>
                                                        <h4><img src={`${process.env.PUBLIC_URL}/assets/web/location-1.png`} />{schoolData.school_location}</h4>

                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {todayHomework(homeworknotice)}
                                    <div className="member-right">
                                        <h3>Members in this Class ({myfriends.length})</h3>
                                        <div className="mem-list">
                                            <ul>
                                                {myfriends && myfriends.map((value, i) => (
                                                    <li key={i}>
                                                        <Link to="/otherUserProfile" state={{ "otherUserData": getPassDataOtherUser(value) }}>
                                                            <img src={value?.avatar_full_path != null ? `${value?.avatar_full_path}` : `${env.PRO_IMG}pro-img.png`} />
                                                            {value?.display_user_name != "" ? value?.display_user_name : value?.username}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    </div>

                                    {/* <div className="chat-with-friend">
                                        <div className="chat-head">
                                            <h3>Chat with Friends (5)</h3>
                                        </div>
                                        <div className="chat-body">
                                            <div className="swiper chat-swiper">
                                                <div className="swiper-wrapper">
                                                    <div className="swiper-slide">
                                                        <a href="#">
                                                            <img src={`${env.PRO_IMG}pro-img.png`} />
                                                            Jennifer
                                                        </a>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <a href="#">
                                                            <img src={`${env.PRO_IMG}pro-img.png`} />
                                                            Cathy
                                                        </a>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <a href="#">
                                                            <img src={`${env.PRO_IMG}pro-img.png`} />
                                                            Courtney
                                                        </a>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <a href="#">
                                                            <img src={`${env.PRO_IMG}pro-img.png`} />
                                                            Jennifer
                                                        </a>
                                                    </div>
                                                    <div className="swiper-slide">
                                                        <a href="#">
                                                            <img src={`${env.PRO_IMG}pro-img.png`} />
                                                            Jennifer
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Modal report_type={reportType} report_data={reportPostData} isUpdate={(data) => data === true && (setReportPostData(""), setInstructionData(""))} instructrionData={instructionData} />


        </React.Fragment >
    )
}

export default My_Class
