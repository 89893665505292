import React, { useEffect, useState } from 'react'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { fun_zone_list } from '../../../services/user_apis'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import $ from 'jquery'

const Fun_Zone = ({ socketData }) => {

    const [fun_zone, setFun_zone] = useState([])
    let navigate = useNavigate();
    let location = useLocation();
    // console.log("location  ====> ", location)
    useEffect(() => {
        if (location.state) {
            $(".nav-link").removeClass("active")
            $("." + location.state.activeTab).addClass("active")
        }
        const fun_zone_list2 = async () => {

            try {
                const response = await fun_zone_list(location.state ? location.state.key : "game")
                // console.log(response, "yash s te")
                if (response.code === 1) {
                    setFun_zone(response.data)
                } else {
                    setFun_zone([])
                }
            }
            catch (e) {
                navigate('/')
            }

        }
        fun_zone_list2()
    }, [])

    const fun_zone_list1 = async (value) => {
        const fun_zone_list_wrap = async () => {
            const data = await fun_zone_list(value)
            if (data.code === 1) {
                setFun_zone(data.data)
            } else {
                setFun_zone([])
            }
        }
        fun_zone_list_wrap()
    }

    return (
        <React.Fragment>


            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="nav nav-tabs funzone-tab">
                                    <li className="nav-item" onClick={() => fun_zone_list1("game")} >
                                        {/* <a className="nav-link active" data-toggle="tab" href="#games" >Games</a> */}
                                        {/* <Link toclassName="nav-link active" data-toggle="tab" onClick={fun_zone_list1("game")} >Games</Link> */}
                                        <a className="nav-link gameTab active" data-toggle="tab" value="coding">Games</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("cartoon")}>
                                        {/* <Link className="nav-link active" data-toggle="tab" onClick={fun_zone_list1("cartoon")} >Cartoon</Link> */}
                                        <a className="nav-link cartoonTab" data-toggle="tab" value="coding">Cartoons</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("well_being")}>
                                        {/* <Link className="nav-link active" data-toggle="tab" onClick={fun_zone_list1("well_being")} >Well being</Link> */}
                                        <a className="nav-link wellBeingTab" data-toggle="tab" value="coding">Wellbeing</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("meme")}>
                                        {/* <Link className="nav-link" data-toggle="tab" value="meme">Memes</Link> */}
                                        <a className="nav-link memesTab" data-toggle="tab" value="coding">Memes</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("video")}>
                                        {/* <Link className="nav-link" data-toggle="tab" value="video">videos</Link> */}
                                        <a className="nav-link videoTab" data-toggle="tab" value="coding">Videos</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("coding")}>
                                        <a className="nav-link codingTab" data-toggle="tab" value="coding">Coding</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="games">
                                        <div className="row mt-3">
                                            {(fun_zone.length > 0) ? fun_zone.map((value, i) => (
                                                <div className="col-md-6" key={i}>
                                                    <div className="game">
                                                        <div className="box">
                                                            <img src={value.fun_zone_cover_image_full_path} />
                                                            <div className="text">
                                                                <h6>
                                                                    {value.fun_zone_title}
                                                                </h6>
                                                                <p>
                                                                    {value.fun_zone_description}
                                                                </p>
                                                                <Link to="/funzone-details" state={{ funzonedata: value }} className="play web">
                                                                    Play
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <div className="col-md-12"><center><h4>Coming Soon</h4></center></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </React.Fragment>
    )
}

export default Fun_Zone
