import React, { useEffect, useState } from 'react'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { get_tutorials } from '../../../services/user_apis'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import $ from 'jquery'
import MoreLess from '../common/MoreLess'

const Tutorial = ({ socketData }) => {

    const [tutorial, setTutorial] = useState([])
    const [circular, setcircular] = React.useState(true);
    let navigate = useNavigate();
    let location = useLocation();
    // console.log("location  ====> ", location)
    useEffect(() => {
        if (location.state) {
            $(".nav-link").removeClass("active")
            $("." + location.state.activeTab).addClass("active")
        }
        const tutorial_list_all = async () => {

            try {
                const response = await get_tutorials()
                // console.log(response, "yash s te")
                if (response.code === 1) {
                    setTutorial(response.data)
                } else {
                    setTutorial([])
                }
            }
            catch (e) {
                navigate('/')
            }

        }
        tutorial_list_all();
    }, [])

    const tutorial_list = async () => {
        setcircular(true);
        const tutorial_list_wrap = async () => {
            const data = await get_tutorials()
            if (data.code === 1) {
                setTutorial(data.data)
                setcircular(false);
            } else {
                setTutorial([])
                setcircular(false);
            }
        }
        tutorial_list_wrap()
    }

    const showMoreLess = async (id) => {
        var elmId = $(`#show-more-content-${id}`).css("display");
        if (elmId === "none") {
            $(`#show-more-content-${id}`).css('display', 'inline');
            $(`#show-more-${id}`).hide();
            $(`#show-less-${id}`).show();
        } else {
            $(`#show-more-content-${id}`).css('display', 'none');
            $(`#show-less-${id}`).hide();
            $(`#show-more-${id}`).show();
        }
    }

    const loadingContent = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (tutorial.length > 0) {
                return <div className="col-md-12"><center><h4>Coming Soon</h4></center></div>
            }
        }
    }

    return (
        <React.Fragment>


            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="nav nav-tabs funzone-tab">
                                    <li className="nav-item" onClick={() => tutorial_list()} >
                                        <a className="nav-link gameTab active" data-toggle="tab" value="coding">Social Media Training Curriculum</a>
                                    </li>
                                </ul>
                                <div className="row" style={{ 'margin-top': '25px' }}>
                                    <div className="col-md-12">
                                        <h4>What is Cybersmarties?</h4>
                                    </div>
                                    <div className="col-md-12" style={{ 'margin-top':'-30px', 'margin-buttom': '0px', 'text-align': 'left', 'padding': '40px' }}>
                                        <p style={{ display: 'inline'}}>Cybersmarties is a School Safe Social Media Training Platform for Kids.
                                        We were never shown how to use social media, it just happened and successive
                                        generations continually fall victim to the same issues of cyberbullying, grooming,
                                        peer pressure, scams, screen addiction, access to inappropriate behaviour and
                                        increasing social anxiety. <span id="show-more-content-tutorial">This is because no one has been taught how to use it. As
                                        teachers you know the importance of educating core academic skills and life skills
                                        from the youngest age in the development of a child into a well-rounded, kind and
                                        responsible young adult. If the basics are not taught then children are not going to
                                        learn them on their own. As social media has crept into the classroom causing
                                        multiple issues the Cybersmarties platform and curriculum was developed in Ireland
                                        in 2015 for schools to help teachers educate kids how to engage with the digital
                                        world, communicate in a kinder, empathetic way, learn life skills to encourage
                                        positive wellbeing and learn the danger signs of the internet to better protect
                                        themselves. Cybersmarties is partners with An Garda Siochána and is the only
                                        company in Ireland to receive the An Garda Siochána Patent for Safety as being
                                        100% safe for kids. We are also a UNICEF Global Partner and operate in multiple
                                        countries across the globe. Cybersmarties operates from 9am to 9pm
                                        We know how busy teachers are so Cybersmarties Social Media Curriculum was
                                        developed to allow teachers to Watch and Teach, or read the material oneself and
                                        use it to Guide and Learn. Cybersmarties is a fully monitored, child only platform
                                        and Cybersmarties monitoring staff are monitoring all activity by the children both in
                                        and out of school. Claire, Our Avatar Wellbeing Assistant continually engages
                                        with children every day to identify any emotional issues they are feeling and shows
                                        them videos how to deal with those issues so that they are building key life skills to
                                        be aware and protect their own wellbeing. Every 2 months each school receives a
                                        Wellbeing Report which gives an overall wellbeing assessment of the children in
                                        the school and has been proven to be a very insightful aid to teachers to inform key
                                        strategic decisions.</span></p>
                                        <button id="show-less-tutorial" onClick={() => { showMoreLess('tutorial') }}  style={{ 'display': 'none', 'background-color': '#4285f4', 'color': '#fff', 'margin-left': '10px', 'padding-right': '10px','padding-left': '10px', 'border': '0px', 'border-radius': '10px' }}>Show less...</button>
                                        <button id="show-more-tutorial" onClick={() => { showMoreLess('tutorial') }} style={{ 'background-color': '#4285f4', 'color': '#fff', 'margin-left': '5px', 'padding-right': '10px','padding-left': '10px', 'border': '0px', 'border-radius': '10px' }}>Show more...</button>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="games">
                                        <div className="row mt-3">
                                            {(tutorial.length > 0) ? tutorial.map((value, i) => (
                                                <div className="col-md-6" key={i}>
                                                    <div className="game">
                                                        <div className="box">
                                                            <img src={value.tutorial_image_path? value.tutorial_image_path : ''} />
                                                            <div className="text">
                                                                <h5>
                                                                    {value.title}
                                                                </h5>
                                                                <MoreLess text={value.description} words={40} />
                                                                <Link to="/tutorial-details" state={{ tutorialdata: value }} className="play web">
                                                                    Play
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : loadingContent() }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </React.Fragment>
    )
}

export default Tutorial
