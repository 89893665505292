import React from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { useLocation, useNavigate } from 'react-router-dom';
import { get_homework_details, add_homework_submission } from '../../../services/user_apis'
import { timeConvert } from '../../../Utils/TimeUtils'
import moment from 'moment'
import { uploadImage } from "../../../services/imageUpload";
import $ from 'jquery'
import { CircularProgress } from '@mui/material';


const Generated_Reports = ({ socketData }) => {

    const { state } = useLocation();
    let navigate = useNavigate();
    const [homeWorkDetails, setHomeworkDetails] = React.useState();
    const [upload, setUpload] = React.useState([]);
    const [uploadFilePath, setUploadFilePath] = React.useState([]);
    const [circular, setcircular] = React.useState(false)
    const [homwork_status, sethomwork_status] = React.useState("");
    const [fullsize, setfullsize] = React.useState("");

    const user = JSON.parse(localStorage.getItem('user'))

    window.Buffer = window.Buffer || require("buffer").Buffer;

    React.useEffect(() => {
        getHomeWorkDetails()
    }, [state])

    const getHomeWorkDetails = async () => {
        setcircular(true)
        const post_body = {
            "homework_id": state.homworkData.homework_id,
            "division_id": user.division_id,
            "class_id": user.class_id,
            "student_id": user.user_id
        }
        const response = await get_homework_details(post_body)
        setcircular(false)
        if (response.code === 1) {
            sethomwork_status(response?.data?.status)
            setHomeworkDetails(response.data)
        }
    }

    const changeHandler = (event) => {
        let tmpFile = event.target.files[0]
        setUpload([...upload, tmpFile])

        uploadImage(tmpFile, (callbackData) => {
            var fileType = "word"
            if (tmpFile.type.includes("image")) {
                fileType = "image"
            } else if (tmpFile.type.includes("pdf")) {
                fileType = "pdf"
            } else {
                fileType = "word"
            }
            // console.log("File Type", fileType);

            var tempData = { "media": callbackData, "file_type": fileType }
            setUploadFilePath([...uploadFilePath, tempData])
            // console.log("uploaded data ===>", uploadFilePath)
        })
        event.target.value = ''

    }

    const removeFile = (index) => {
        let tmpData = Object.assign([], upload)
        tmpData.splice(index, 1);
        setUpload(tmpData)

        let tmpDataFilePath = uploadFilePath
        tmpDataFilePath.splice(index, 1);
        setUploadFilePath(tmpDataFilePath)
    }


    const clickSubmitButton = async (e) => {
        e.preventDefault();
        let homeWorkBody = {
            class_id: user.class_id,
            division_id: user.division_id,
            user_id: user.user_id,
            homework_id: homeWorkDetails.homework_id,
            submission_type: homeWorkDetails.homework_type,
            submission_answer: $("#QuestionAnswer").val(),
            submission_file_list: uploadFilePath,
        }

        if (uploadFilePath.length <= 0) {
            delete homeWorkBody.submission_file_list
        }
        if (homeWorkDetails.status === "rejected") {
            homeWorkBody["status"] = "resubmitted"
        }
        // console.log("SEND PARAMS ==> ", homeWorkBody)

        let response = await add_homework_submission(homeWorkBody)

        // console.log("Add Home Work submission", response);
        if (response.code === 1) {
            navigate(-1)
        }
        return
    }

    const handleResubmit = async () => {
        sethomwork_status("pending")
    }

    return (
        <React.Fragment>
            {circular && <CircularProgress />}
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <section className="post-section pt-4">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                        <div className="my-school">
                                            <div className="teacher-pro">
                                                <img src="assets/images/academic.png" />
                                                <h2>{homeWorkDetails?.homework_title}</h2>
                                                <label className="posted"><b>{moment(homeWorkDetails?.created_at).format("DD MMMM, YYYY | h:mm a")}</b> <i className="fas fa-circle circle-title"></i> Posted by <span>{homeWorkDetails?.teacher_name}</span></label>
                                                <div className="last-date-submission">
                                                    <p>Last Submission Date: <b className="text-dark">{moment(homeWorkDetails?.homework_last_submission_date).format("DD MMMM, YYYY")}</b></p>
                                                    {homwork_status != "pending" && (
                                                        homwork_status === "rejected" ?
                                                            <>
                                                                <p className="text-danger">{`Rejected :`} <span className='text-dark'> by {homeWorkDetails?.teacher_name}</span></p>
                                                                <div className="form-group text-center">
                                                                    <button className="cybersmart-btn-m btn-block" type='button' onClick={() => handleResubmit()}>Resubmit Homework</button>
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="score"><span className="text-success">{homeWorkDetails?.status}</span></div>
                                                    )}
                                                </div>
                                            </div>
                                            {homwork_status === "pending" ?
                                                <form id="form_validation" method="" onSubmit={clickSubmitButton} action="#">
                                                    <div className="school-section mt-3">
                                                        <div className="row justify-content-center pt-4">
                                                            <div className="col-lg-12">
                                                                <div className="academic-detail">
                                                                    <h4 className="text-color-blue">Questions</h4>
                                                                    <h5>{homeWorkDetails?.homework_question}</h5>

                                                                    {homeWorkDetails?.homework_file_list && <div className='homework-details'>
                                                                        <div className="attached-file">Attached files</div><br/>
                                                                        {homeWorkDetails?.homework_file_list != '' && homeWorkDetails?.homework_file_list.map((item, i) => {
                                                                            return(
                                                                                (item.file_type === "image" )? 
                                                                                <>
                                                                            {/*} <a target="_blank" href={item.media_file_full_path} > <img src={item.media_file_full_path} width="200" height="200"/></a>
                                                                                <div className='m-1'>   </div> */}

                                                                                <a key={i} target="_blank" href={item.media_file_full_path} title={homeWorkDetails?.homework_question + ' file'}><div className='m-1'><img src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : (item.file_type === "image" ) ? `${item.media_file_full_path}` : process.env.PUBLIC_URL + "/assets/images/doc.png"} /></div></a>

                                                                                </>
                                                                                :
                                                                                <>
                                                                                <a  key={i}target="_blank" href={item.media_file_full_path} title={homeWorkDetails?.homework_question + ' file'}><div className='m-1'><img src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : (item.file_type === "image" ) ? process.env.PUBLIC_URL + "/assets/images/file.png" : process.env.PUBLIC_URL + "/assets/images/doc.png"} /></div></a>
                                                                                </> 
                                                                                )
                                                                        })}
                                                                    </div>}
                                                                    {(homeWorkDetails?.homework_type === "both" || homeWorkDetails?.homework_type === "text") &&
                                                                        <div className="form-group">
                                                                            <textarea id='QuestionAnswer' className="form-control" placeholder="Write Here..." rows="5"></textarea>
                                                                        </div>

                                                                    }
                                                                    {(homeWorkDetails?.homework_type === "both" || homeWorkDetails?.homework_type === "file") &&
                                                                        <div className="form-group">
                                                                            <div className="upload-view-box">
                                                                                <label className="upload-image">
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/add.png"} className="addimg" />
                                                                                    <h5><img src={process.env.PUBLIC_URL + "/assets/images/u-img.png"} className="up" /> Upload Image</h5>
                                                                                    <input type="file" name="" onChange={changeHandler} />
                                                                                </label>

                                                                                <div className="upload-view">
                                                                                    {upload != '' && upload.map((item, index) => (
                                                                                        <div className='m-1' key={index}><img src={item.type === "application/pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : item.type === "image/png" ? process.env.PUBLIC_URL + "/assets/images/file.png" : process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{ width: 35, height: 35 }} /> {item.name} <i className="far fa-times" onClick={() => removeFile(index)} ></i></div>
                                                                                    ))}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className="form-group text-center">
                                                                        <button className="cybersmart-btn-m btn-block" type='submit'>Submit Homework</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                :
                                                <div className="school-section mt-3">
                                                    <div className="row justify-content-center pt-4">
                                                        <div className="col-lg-12">
                                                            <div className="academic-detail">
                                                                <h4 className="text-color-blue">Questions</h4>
                                                                <h5>{homeWorkDetails?.homework_question}</h5>

                                                                {homeWorkDetails?.homework_file_list && <div className='homework-details'>
                                                                    {homeWorkDetails?.homework_file_list != '' && homeWorkDetails?.homework_file_list.map((item, i) => (
                                                                        <a key={i} className='m-1' target={"_blank"} href={item.media_file_full_path}><img src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : item.file_type === "image" ? process.env.PUBLIC_URL + "/assets/images/file.png" : process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{ width: 35, height: 35 }} /> {item.media} </a>
                                                                    ))}
                                                                </div>}
                                                                <p className='text-left mt-5'>Answer: </p>
                                                                <p className='text-left'>{homeWorkDetails?.student_submission_data?.answer}</p>
                                                                <div className='homework-details ml-0'>
                                                                    {homeWorkDetails?.student_submission_data?.submission_file_list && homeWorkDetails?.student_submission_data?.submission_file_list.map((item, i) => (
                                                                        <a key={i} className='m-1 ml-0' target={"_blank"} href={item.media_file_full_path}><img src={item.file_type === "pdf" ? process.env.PUBLIC_URL + "/assets/images/pdf.png" : item.file_type === "image" ? process.env.PUBLIC_URL + "/assets/images/file.png" : process.env.PUBLIC_URL + "/assets/images/doc.png"} style={{ width: 35, height: 35 }} /> {item.media} </a>
                                                                    ))}
                                                                </div>
                                                                {homeWorkDetails?.student_submission_data?.remark != "" &&
                                                                    <div className="remarks-box bg-light">
                                                                        <h5>Remarks</h5>
                                                                        {homwork_status === "rejected" ? <p className="text-danger">{homeWorkDetails?.student_submission_data?.remark}</p>
                                                                            : <p className="text-success">{homeWorkDetails?.student_submission_data?.remark}</p>}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Modal />

        </React.Fragment>
    )
}

export default Generated_Reports
