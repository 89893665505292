import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import env from 'react-dotenv'
import { Link, useNavigate } from 'react-router-dom'
import Left_Panel_Modal from "./modal/Left_Panel_Modal";
import { avatar_list, update_user_detail, add_post, get_general_notifications } from '../../../services/user_apis';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';

const Left_Panel = () => {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userData, setUserData] = useState();
    const [generalNotificationData, setGeneralNotificationData] = React.useState([]);
    const [avatarData, setAvatarData] = React.useState([])

    React.useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('user')))
        avatarList()
        generalNotificationList();
        const interval = setInterval(function() {
            $(".jsBlink").toggle();
        }, 1000);
        setTimeout(() => {
                        clearInterval(interval);
                        $(".jsBlink").show();
        }, 4000);
    }, []);
    const onClickClariaBot = (event) => {
        $('.clarie').addClass('clarie-active')
        $('.clarie > #fee1').addClass('feeling-active');
        $('.chat-with-friend').removeClass('chat-with-desk-friend-active')
        $('.chat-desk-icon').hide()
        $(".chatbox").hide();
        event.stopImmediatePropagation();
    }

    const avatarList = async () => {
        let post_body = {
            type: user.user_role
        }
        const response = await avatar_list(post_body)
        if (response.code === 1) {
            setAvatarData(response.data)
        }
    }

    const generalNotificationList = async () => {
        let post_body = {
            user_role: user.user_role,
            classes: user.classes
        }
        const response = await get_general_notifications(post_body)
        if (response.code === 1) {
            setGeneralNotificationData(response.data)
        }
    }

    const updateUserProfile = async (avatar_id) => {
        const post_body = {
            avatar_id: avatar_id,
        }

        const response = await update_user_detail(post_body)
        if (response.code === 1) {
            // console.log("update user data ", response)
            setUserData(response.data)
            localStorage.setItem('user', JSON.stringify(response.data));
            // const postData = {
            //     class_id: response.data.class_id,
            //     division_id: response.data.division_id,
            //     permission: response.data.user_role,
            //     post_category: 'avatar',
            //     post_description: `<img src=${response.data.avatar_full_path} alt=${response.data.display_user_name} />`,
            //     school_id: response.data.school_id,
            // }
            // await add_post(postData);
            // isUpdate(true)
            navigate(0)
        }
    }

    return (
        <React.Fragment>
            <div className="left-panel text-left">
                <div className="logo-main">
                    <a href="../home"><img src={process.env.PUBLIC_URL + "/assets/images/CSLogo.png"} /></a>
                </div>
                <div className="profile">
                    <div className="p-image">
                        {/* <img src={`${env.PRO_IMG}pro-img.png`} /> */}
                        <img src={userData && user.avatar_full_path} />
                    </div>
                    <div className="p-title">
                        <p>{user?.display_user_name != "" ? user?.display_user_name : user.username}</p>
                        <p><Link to="/my-profile">Go to Profile</Link></p>

                    </div>
                </div>
                <div className="nav1">
                    <ul>
                        <li><Link className="active" to="/home"><img src={`${process.env.PUBLIC_URL + "/" + env.HOME_RUN_PNG}home-run.png`} /> {t('Home')}</Link></li>
                        <li><Link to="/school-profile"><img src={`${process.env.PUBLIC_URL + "/" + env.SCHOOL_PNG}school.png`} /> {t('My School')}</Link></li>
                        <li><Link to={user.user_role === "student" ? "/my-class" : "/my-class-teacher"}><img src={`${process.env.PUBLIC_URL + "/" + env.CLASS_PNG}class.png`} /> {t('My Class')}</Link></li>
                        {user.user_role === "teacher" && <li><Link to="/tutorial"><img src={`${process.env.PUBLIC_URL + "/" + env.TEAM_PNG}training.png`} /> Training </Link></li>}
                        <li><Link to={user.user_role === "student" ? "/my-homework" : "/my-homework-teacher"}><img src={`${process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG}homework.png`} /> {t('Academic')}</Link></li>
                        {user.user_role === "student" && <li><Link to="/add-classmate"><img src={`${process.env.PUBLIC_URL + "/" + env.TEAM_PNG}team-1.png`} /> {t('Add your Friend')}</Link></li>}
                    </ul>
                </div>
                <hr />
                <div className="nav1">
                    <ul>
                        {/* {user.user_role === "student" && <li><a className="active" href="add-friend.php"><img src={`${process.env.PUBLIC_URL + "/" + env.MEMBER_PNG}member.png`} /> 40 Member</a></li>} */}
                        {/* {user.user_role === "student" && <li><a href="#"><img src={`${process.env.PUBLIC_URL + "/" + env.MEMBERSHIP_PNG}membership.png`} /> 21 Friendships</a></li>} */}

                        {user.user_role === "student" && <li><Link to="/funezone" state={{ activeTab: "gameTab", key: "game" }}><img src={`${process.env.PUBLIC_URL + "/" + env.PARTY_PNG}party.png`} /> {t('Fun Zone')}</Link>
                            {generalNotificationData && (generalNotificationData?.funzone?.total > 0) && <div class="new_notify jsBlink"><p className="badge-text">New</p></div>}
                        </li>}
                        <li >
                            <a href="#" data-target="#Avtar_Modal" data-toggle="modal" ><img src={`${process.env.PUBLIC_URL + "/" + env.CHANGE_AVATAR_PNG}chnage-avatar.png`} /> {t('Change Avatar')}</a>
                            {generalNotificationData && (generalNotificationData?.avatar?.total > 0) && <div className="new_notify jsBlink"><p className="badge-text">New</p></div>}
                        </li>
                        {user.user_role === "teacher" && <li><Link to="/reportAction"><img src={process.env.PUBLIC_URL +"/assets/images/home/course/report.svg"} /> {t('Reports')}</Link></li>}
                        {user.user_role === "student" && <li><a href="javascript:" onClick={onClickClariaBot} className="chat-clarie"><img src={`${process.env.PUBLIC_URL + "/" + env.CLARIE_SMALL_PNG}claire_small.png`} /> {t('Chat with Claire')}</a></li>}
                    </ul>
                </div>
                <hr />
                <div className="nav1">
                    <ul>

                        <li><Link to='/faq'>{t('FAQ')}</Link></li>
                        <li><Link to="/about">{t('About Us')}</Link></li>
                        {/* <li><Link to="/contact">{t('Contact Us')}</Link></li> */}
                        <li><Link to="/terms-condition">{t('Terms & Conditions')}</Link></li>
                        <li><Link to="/Privacy">{t('Privacy Policy')}</Link></li>
                    </ul>
                </div>
                <div className="garda">
                    <div className="g-title">
                        <p>Global partners of:</p>
                    </div>
                    <div className="g-image">
                        <img src={`${process.env.PUBLIC_URL + "/" + env.BITMAP_PNG}bitmap-copy-2.png`} />
                        <img src={`${process.env.PUBLIC_URL + "/" + env.BITMAP_THREE_PNG}bitmap-copy-3.png`} />
                    </div>

                </div>
            </div>
            <div className='mobile-nav'>
                <nav className="navbar nav-bar fixed-bottom">
                    <a className="navbar-link" href="../my-homework">
                        <img src={`${process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG}homework.png`} width="30" height="30" alt="" />
                        <div>
                            Homework
                        </div>

                    </a>
                    <a className="navbar-link" href="../my-class">
                        <img src={`${process.env.PUBLIC_URL + "/" + env.CLASS_PNG}class.png`} width="30" height="30" alt="" />
                        <div>
                            Myclass </div>
                    </a>
                    <a className="navbar-link" href="../my-profile">
                        <img src={userData && user.avatar_full_path} width="30" height="30" alt="" />
                        <div>
                            My Profile </div>
                    </a>
                    <a className="navbar-link" href="../school-profile">
                        <img src={`${process.env.PUBLIC_URL + "/" + env.SCHOOL_PNG}school.png`} width="30" height="30" alt="" />
                        <div>
                            My School </div>
                    </a>
                    <a className="navbar-link" href="../funezone">
                        <img src={`${process.env.PUBLIC_URL + "/" + env.PARTY_PNG}party.png`} width="30" height="30" alt="" />
                        <div>
                            Fun Zone </div>
                    </a>
                </nav>
            </div>

            <div className="mobile-menu">
                <div className="mobile-logo">
                    <a href="../home" className="m-logo"><img src={`assets/images/CSLogo.png`} /></a>
                    <a href="#" className="mb-close"><img src="assets/web/close.svg" /> Close</a>
                </div>
                <div className="nav1">
                    <ul>
                        <li><a className="active" href="home.php"><img src={`${process.env.PUBLIC_URL + "/" + env.HOME_RUN_PNG}home-run.png`} /> Home</a></li>
                        <li><a href="school-profile.php"><img src={`${process.env.PUBLIC_URL + "/" + env.SCHOOL_PNG}school.png`} /> MySchool</a></li>
                        <li><a href="my-className.php"><img src={`${process.env.PUBLIC_URL + "/" + env.CLASS_PNG}className.png`} /> My Class</a></li>
                        <li><a href="#"><img src={`${process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG}homework.png`} /> Academic</a></li>
                        <li><a href="add-classmate.php"><img src={`${process.env.PUBLIC_URL + "/" + env.TEAM_PNG}team-1.png`} /> Add your Friend</a></li>
                    </ul>
                </div>
                <hr />
                <div className="nav1">
                    <ul>
                        {user.user_role === "teacher" && <li><a className="active" href="add-friend.php"><img src={`${process.env.PUBLIC_URL + "/" + env.MEMBER_PNG}member.png`} /> 40 Member</a></li>}
                        <li><a href="#"><img src={`${process.env.PUBLIC_URL + "/" + env.MEMBERSHIP_PNG}membership.png`} /> 21 Friendships</a></li>
                        <li><a href="#"><img src={`${process.env.PUBLIC_URL + "/" + env.POST_PNG}post.png`} /> 14 Posts</a></li>
                        <li><a href="#"><img src={`${process.env.PUBLIC_URL + "/" + env.PHOTO_PNG}photo.png`} /> 102 Photos</a></li>
                        <li><a href="javascript:" onClick={onClickClariaBot} className="chat-clarie"><img src={`${process.env.PUBLIC_URL + "/" + env.CLARIE_PNG}clarie.png`} /> Chat with Claire</a></li>
                    </ul>
                </div>
                <hr />
                <div className="nav1">
                    <ul>

                        <li><Link to="/funezone" state={{ activeTab: "gameTab", key: "game" }}><img src={`${process.env.PUBLIC_URL + "/" + env.PARTY_PNG}party.png`} /> Fun Zone</Link></li>

                        <li><a href="#" data-target="#ChangeAvtar_Modal" data-toggle="modal"><img src={`${process.env.PUBLIC_URL + "/" + env.CHANGE_AVATAR_PNG}chnage-avatar.png`} /> Change Avatar</a></li>
                    </ul>
                </div>
                <div className="garda">
                    <div className="g-title">
                        <p>In collaboration with  UNICEF-End Violence:</p>
                    </div>
                    <div className="g-image">
                        <img src={`${process.env.PUBLIC_URL + "/" + env.BITMAP_PNG}bitmap-copy-2.png`} />
                        <img src={`${process.env.PUBLIC_URL + "/" + env.BITMAP_THREE_PNG}bitmap-copy-3.png`} />
                    </div>

                </div>
            </div>

            <div id="Avtar_Modal" className="modal fade" role="dialog" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">

                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal"></button>
                            <h4 className="modal-title w-100">Choose a new avatar
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div className="avtar-list">
                                {avatarData && avatarData.map((item, index) => (
                                    <div className="text-radio" key={index}>
                                        <input id={`leftavtar${index}`} value={`${item.avatar_id}`} name="leftavtar" type="radio" data-dismiss="modal" onChange={(e) => updateUserProfile(e.target.value)} />
                                        <label for={`leftavtar${index}`} >
                                            <img src={item?.avatar_full_path} />
                                            <span>Selected Avatar</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <script>
                document.getElementsByClassName('left-panel')[0].style.minHeight = window.innerHeight + 'px'
            </script>

        </React.Fragment>
    )
}

export default Left_Panel
