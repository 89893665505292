import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import GLOBAL_CONSTANT from "../config/global";

const headers = {
  'api-key': 'n4lBiwpD7XqiqSYWQkczLCiGl5QBpPii',
  'Content-Type': 'text/plain',
}
if (GLOBAL_CONSTANT.OFFLINE === 'true') headers['offline-access'] = 'true';

const showMessage = (msg) => {
  Swal.fire({
    title: 'Cybersmarties',
    text: msg,
    confirmButtonText: 'Okay',
  })
}

const logoutMessage = (msg, error) => {
  Swal.fire({
    title: 'Cybersmarties',
    text: msg,
    confirmButtonText: 'Logout',
    showConfirmButton: true,
    timer: 100000
  }).then((result) => {
    if (result) {
      localStorage.removeItem('user')
      let path = window.location.protocol + "//" + window.location.host + "/"
      window.location.href = path;
      return Promise.reject(error);
    }
  })
}

const axiosClient = axios.create({
  baseURL: `${GLOBAL_CONSTANT.API_URL}/api/v1/`,
  headers: headers
});

// axiosClient.interceptors.request.use(function (config) {
//   config.headers['token'] = "WUVpHuILPofAO9NAmy7QxJFDj1rYdqiRJNv5RFQi8Nk"
//   return config;
// }

// );

axiosClient.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      if (response.data.code === "0" || response.data.code === 0) {
        if (localStorage.getItem("user") && (response.data.message).includes("9pm to 7am")) {
          localStorage.removeItem('user');
          localStorage.removeItem('isLogin');
          localStorage.removeItem('language');
          localStorage.removeItem('userId');
          localStorage.removeItem("nCount");
          let path = window.location.protocol + "//" + window.location.host + "/"
          window.location.href = path;
          return Promise.reject('');
        } else {
          showMessage(response.data.message)
        }
      }
      return response;
    }
    else {
      showMessage("Somthing Went Wrong!")
      return response;
    }
  },
  function (error) {
    let res = error.response;

    if (res.status === 401) {
      logoutMessage(res.data.message, error);
    }
  }
);

export {
  axiosClient
};

