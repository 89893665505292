import React, { useState } from "react";
import env from "react-dotenv";
import { Link, useNavigate } from "react-router-dom";
import { user_login, online_offline } from '../../../services/user_apis';
import Swal from 'sweetalert2'
const Login = (props) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [invalid, setinvalid] = useState(false)
    const [visible, setVisibility] = useState(true);
    const [status, setStatus] = useState("");

    const navigate = useNavigate()
    props.chatHandle(false);

    React.useEffect(() => {
        localStorage.setItem("language", "en")
        onlineOrOffline();
    }, []);

    const onlineOrOffline = async () => {
        let resp = await online_offline();
        if (resp.code === '-1') setStatus('online');
        if (resp.code === '-2') setStatus('maintainence');
        if (resp.code === 0) setStatus('offline');
    }
    const showMessage = (msg) => {
        Swal.fire({
            title: 'Cybersmarties',
            text: msg,
            confirmButtonText: 'Okay'
        })
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        let login_body = {
            username,
            user_password: password,
            device_type: "W",
            os_version: "os",
            device_name: "device",
            ip: "3.4.5",
            device_token: "device_token",
            token: "abc"
        }
        let login_body1 = JSON.stringify(login_body)
        let response = await user_login(login_body1)
        if (response.code === 1) {
            // console.log(response.data);
            localStorage.setItem('user', JSON.stringify(response.data));
            localStorage.setItem("userId", response.data.user_id);
            localStorage.setItem("isLogin", true);
            localStorage.setItem("nCount", 0);
            setinvalid(false)
            // navigate("/home")
            loginRedirectCall()
            props.chatHandle(true);

        } else if (response.code === -1) {
            showMessage(response.message)
        } else {
            setinvalid(true)
        }

        return
    };


    function loginRedirectCall() {
        let path = window.location.protocol + "//" + window.location.host + "/home"
        window.location.href = path;
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    const offlineComponent = () => {
        return (
        <div className="col-lg-12">
            <br />
            <div className="form-group text-center padding10">
                <p>Hi Cybersmartie!!, Cybersmarties platform shuts down every night from 9pm to 7am. It is important to spend time with your family and get at least 8 hours of good sleep every night to stay positive and healthy. You have a big day tomorrow to look forward to, so get a good night's sleep. See you in the morning!</p>
            </div>
        </div>)
    }

    const maintenaceComponent = () => {
        return (
        <div className="col-lg-12">
            <br />
            <div className="form-group text-center padding10">
                <p>Hi Friends, we are making some changes to the platform to serve you better. It will be up again shortly. Can't wait to see you back again! :)</p>
            </div>
        </div>)
    }

    const emergencyOffline = () => {
        return (
            <div className="col-lg-12">
            <br />
            <div className="form-group text-center padding10">
                <p>Sorry kids, we need to give Cybersmarties an update. We will be back up and running very soon</p>
            </div>
        </div>)
    }

    const loginComponent = () => {
        return (
            <>
                <div className="col-lg-6">
                    <div className="auth-form">
                        <h2>Login to your Account</h2>
                        <div className="form-group">
                            <input
                                type="text"
                                name=""
                                className="form-control"
                                placeholder="Enter Username"
                                value={username}
                                onChange={(e) => {
                                    setUsername(
                                        e.target.value
                                    );
                                }}
                                required
                                onKeyPress={handleKeyPress}
                            />
                        </div>
                        <div className="form-group password-input">
                            <input
                                type={visible ? "password" : "text"}
                                name=""
                                className="form-control"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(
                                        e.target.value
                                    );
                                }}
                                onKeyPress={handleKeyPress}
                                required
                            />
                            <i className={`password-eye-icon ${visible ? 'far fa-eye' : 'far fa-eye-slash'}`} onClick={() => setVisibility(!visible)} id="togglePassword"></i>
                        </div>

                        {invalid && <h4 className="text-danger">Invalid Credentials</h4>}
                        <div className="form-group text-center">
                            <button

                                className="cybersmart-btn btn-block"
                                onClick={handleSubmit}
                            >
                                Login to your Account
                            </button >
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="auth-details">
                        <p>
                            Would you like to chat with friends, make new friends, play games, listen to music, learn how to code, watch cartoons and tons more?
                        </p>
                        <p>
                            Cybersmarties is <span className="text-purple">ONLY FOR KIDS</span>. You can use it on your tablet, PCs, laptop, Chromebook, Mac or smartphones. If you have <span className="text-purple">FORGOTTEN YOUR PASSWORD</span> please ask your teacher.
                        </p>
                    </div>
                </div>
            </>
        );
    }

    return (
        <React.Fragment>
            <div className="content">
                <div className="authentication-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-md-12">
                                <div className="signin-box">
                                    <div className="auth-logo">
                                        <Link to={"/"}>
                                            <img
                                                src={`assets/images/CSLogo.png`}
                                                className="img-fluid"
                                            />
                                        </Link>
                                    </div>
                                    <div className="row">
                                        {status === 'offline' ? offlineComponent() : status === 'maintainence' ? maintenaceComponent() : status === 'online' ? loginComponent() : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
