import React from 'react';
import { Link } from 'react-router-dom';
import env from "react-dotenv";

export const todayHomework = (homeworknotice) => {
    if (homeworknotice.length > 0) {
        return (<div className="notice-box">
            <h3><img src={`${process.env.PUBLIC_URL + "/" + env.HOMEWORK_PNG}star.png`} /> Homework Last Day</h3>
            <div className="article">
                <ul>
                {
                    (homeworknotice.length > 0) && homeworknotice.map((note, i) => {
                        return <li><Link className="homework-widget-list" to={"/homeworkDetails"} state={{ homworkData: note }} title={note.homework_title}>{note.homework_title}</Link></li>
                    })
                }
                </ul>
            </div>
        </div>)
    }
}
